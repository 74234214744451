import styled from 'layout/theme/styled';

export const QuestionDropdownContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const QuestionSelect = styled('select')({
  marginTop: '0.5em',
  width: '800px',
  border: '1px solid black',
  padding: '5px',
  borderRadius: '10px',
});
