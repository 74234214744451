import styled from 'layout/theme/styled';
import { MessageType } from 'pages/AIAssistant/consts';

export const ChatMessagesContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '1em',
});

const ellipsis = `
  @keyframes ellipsis {
    to {
      width: 40px;
    }
  }
  @-webkit-keyframes ellipsis {
    to {
      width: 40px;
    }
  }
`;

export const Loading = styled('span')`
  ${ellipsis}

  &:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 900ms infinite;
    animation: ellipsis steps(4, end) 900ms infinite;
    content: '\\2026'; /* ascii code for the ellipsis character */
    width: 0px;
  }
`;

export const ResponseBoxContainer = styled('div')({
  width: '800px',
  height: '50vh',
  backgroundColor: 'white',
  padding: '1em',
  overflow: 'auto',
  border: '1px solid black',
  borderRadius: '10px',
});

export const MessageContainer = styled('div')<{ props: { message?: MessageType } }>(
  ({ props: { message } }) => ({
    backgroundColor: message!.type === 'user' ? '#e6f7ff' : '#cce6ff',
    color: 'black',
    padding: '0.5em',
    margin: '0.5em',
    borderRadius: '10px',
    textAlign: message!.type === 'user' ? 'right' : 'left',
  })
);
