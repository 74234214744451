import { API } from 'aws-amplify';

type IRetrievalInstructionsResponse = {
  delivery: {
    datasetRegistryId: string;
    providerKey: string;

    retrievalProperties: {
      signedUrlExpiration: Date;
      unsignedUrl: string;
      signedUrl: string;
      credentials: {
        accessKeyId: string;
        secretAccessKey: string;
        sessionToken: string;
        expiration: Date;
      };
      createdAt: Date;
      region: string;
      connectionString: string;
    };
  }[];
};

export default function useApi() {
  const getQuestionResponse = (message: any, documentId: any) =>
    API.post('ai-assistant-api', '/dev/query', {
      body: {
        questionText: message,
        documentId: documentId,
      },
    })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        let errorMessage = 'An error occurred.';
        if (
          error.response &&
          error.response.status === 404 &&
          error.response.data === 'document not available in VectorDB.'
        ) {
          errorMessage = 'Documents not available in database.';
        }
        else if (error.response && error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message;
        }
        return errorMessage;
      }),
    getResponse = (transactionId: any) =>
      API.post('ai-assistant-api', '/dev/getResponse', {
        body: {
          transaction_id: transactionId,
        },
      })
        .then((res) => {
          return res;
        })
        .catch((error) => {
          let errorMessage = 'An error occurred.';
          if (
            error.response &&
            error.response.status === 404 &&
            error.response.data === 'document not available in VectorDB.'
          ) {
            errorMessage = 'Documents not available in database.';
          }
          else if (error.response && error.response.data && error.response.data.message) {
            errorMessage = error.response.data.message;
          }
          return errorMessage;
        }),
    getMetadataQueryResponse = async (message: string) => {
      return API.post('ai-assistant-api', '/dev/metadata-query', {
        body:
        {
          questionText: message,
        },
      })
        .then((res) => {
          return res;
        })
        .catch((error) => {
          if (error.response && error.response.status === 500) {
            return error.response.data;
          }
        });
    },
    getContext = (context: string) =>
      API.post('ai-assistant-api', '/dev/context', {
        body: {
          context: context,
        },
      })
        .then((res) => {
          return res;
        })
        .catch((err) => {
          console.log(err);
        }),
    getPdfSignedUrl = (dataPartitionId: any, datasetRegistryIds: any) => {
      return API.post('api', '/api/dataset/v1/getRetrievalInstructions', {
        headers: {
          'data-partition-id': dataPartitionId,
        },
        body: {
          datasetRegistryIds: datasetRegistryIds,
        },
      })
        .then((res: IRetrievalInstructionsResponse) => {
          console.log('res: ', res);
          const signedUrlFromResponse = res.delivery[0]?.retrievalProperties.signedUrl;
          console.log('signedUrlFromResponse: ', signedUrlFromResponse);
          return signedUrlFromResponse;
        })
        .catch((err) => {
          console.log(err);
        });
    };
  return {
    getQuestionResponse,
    getMetadataQueryResponse,
    getContext,
    getPdfSignedUrl,
    getResponse,
  };
}
