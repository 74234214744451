import { Dispatch, SetStateAction, createContext } from 'react';
import { OsduContextMap } from '../consts';

interface AIAssistantContextProps {
  documentId: string;
  setDocumentId: Dispatch<SetStateAction<string>>;
  osduContext: string;
  setOsduContext: Dispatch<SetStateAction<string>>;
  osduContextMapInMemory: OsduContextMap;
  setOsduContextMapInMemory: Dispatch<SetStateAction<OsduContextMap>>;
  contextDocumentIds: string[];
  setContextDocumentIds: Dispatch<SetStateAction<string[]>>;
  isResponseLoading: boolean;
}

const AIAssistantContext = createContext<Partial<AIAssistantContextProps>>({});

export default AIAssistantContext;
