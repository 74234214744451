interface QuestionsByDocumentId {
  [key: string]: string[];
}

export type MessageType = {
  type: 'server' | 'user' | 'other';
  content: string;
};

export const FILE_OPTIONS = [
  { value: 'lithology_1034039.pdf', label: 'lithology_1034039.pdf' },
  { value: 'forge_well_1034058.pdf', label: 'forge_well_1034058.pdf' },
];

export type OsduContextFile = [string, string];
export type OsduContextMap = Map<string, OsduContextFile[]>;

export const CONTEXT_OPTIONS = new Map<string, OsduContextFile[]>([
  ['No context', []],
  [
    'osdu:master-data--Wellbore:WellForge5832',
    [
      [
        'osdu:dataset--File.Generic:50c54eda0f8d37113571cca4bc512458e2f237a7a51dde35efc5e7452fc1be37',
        'lithology_1034039.pdf',
      ],
      [
        'osdu:dataset--File.Generic:21396a517c1705c0c69616a15d9f52dd598a1a78a6f63e737752d68ba01c0c7d',
        'forge_well_1034058.pdf',
      ],
      [
        'osdu:dataset--File.Generic:5832EOWRDOEpdf',
        '58-32_eowr_doe.pdf',
      ],
      [
        'osdu:dataset--File.Generic:21396a517c1705c0c69616a15d9f52dd598a1a78a6f63e737752d68ba01c0c7d',
        'coringreport.pdf',
      ],
    ],
  ],
  ['osdu:master-data--Wellbore:WellOpalMound91', []],
  ['osdu:master-data--Wellbore:RooseveltHotSprings', []],
  ['osdu:master-data--Wellbore:WellForge5221', []],
  ['osdu:master-data--Wellbore:WellForgeAcord1', []]
]);

export const WELCOME_MESSAGE: MessageType = {
  type: 'server',
  content:
    "Hello! I'm an 47Lining AI Assistant. Please provide a Document ID above to get started, and feel free to ask me any questions related to the document. I'm here to help!",
};

export const QUESTIONS_BY_DOCUMENT_ID: QuestionsByDocumentId = {
  'lithology_1034039.pdf': [
    'Can you concisely describe this document’s subject? ',
    'Can you summarize this document?',
    'Is this document specific to a particular geographic or geologic setting?',
    'Are there restrictions on this document’s use, such as national or security considerations, or is it publicly available?',
    'Briefly list the main rock units encountered in the well',
    'Was the Rhyolite encountered above the plutonic rock permeable?',
    'What other wells is the 58-32 compared to?',
    'How do the plutonic rocks used in the study compare to what was found in other wells penetrating the Mineral Mountains Batholith?',
  ],
  'forge_well_1034058.pdf': [
    'Can you concisely describe this document’s subject?',
    'Can you summarize this document?',
    'Do the granite and monzodiorite have the same thermal conductivity?',
  ],
};
