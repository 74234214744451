import React, { ReactElement, useContext } from 'react';

interface Props {
  textareaRef: any;
  selectedDocumentIdQuestion: string;
  addUserNewMessage: any;
  sendMessage: any;
}

import { EnterQuestionButtonStyled } from './EnterQuestionButton.style';
import AIAssistantContext from 'pages/AIAssistant/context/AIAssistant';
import { RestoreRequestFilterSensitiveLog } from '@aws-sdk/client-s3';

export default function EnterQuestionButton({
  textareaRef,
  selectedDocumentIdQuestion,
  addUserNewMessage,
  sendMessage,
}: Props): ReactElement {
  const { isResponseLoading } = useContext(AIAssistantContext);
  const handleOnScreenEnterButtonClick = () => {
    if (isResponseLoading) return;
    const textAreaQuestion = textareaRef.current?.value.trim();
    const newMessage = textAreaQuestion || selectedDocumentIdQuestion;
    if (newMessage) {
      addUserNewMessage(newMessage);
      textareaRef.current!.value = '';
      sendMessage(newMessage);
    }
  };

  return (
    <EnterQuestionButtonStyled
      type="button"
      disabled={isResponseLoading}
      className="btn btn-primary"
      onClick={handleOnScreenEnterButtonClick}
    >
      Enter
    </EnterQuestionButtonStyled>
  );
}
