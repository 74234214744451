import React, { ReactElement, useState, useRef, useEffect } from 'react';
import { CONTEXT_OPTIONS, FILE_OPTIONS, WELCOME_MESSAGE } from 'pages/AIAssistant/consts';
import DocumentSettingsPanel from '../DocumentSettingsPanel/DocumentSettingsPanel';
import QuestionsPanel from '../QuestionsPanel/QuestionsPanel';
import ChatMessagesArea from '../ChatMessagesArea/ChatMessagesArea';
import { AIChatbotContainer } from './AITab.style';
import useApi from '../../hooks/useApi';
import AIAssistantContext from 'pages/AIAssistant/context/AIAssistant';
import useMessages from 'pages/AIAssistant/hooks/useMessages';
const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

export default function AITab(): ReactElement {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [documentId, setDocumentId] = useState('');
  const [contextDocumentIds, setContextDocumentIds] = useState<string[]>([]);
  const [osduContext, setOsduContext] = useState('');
  const { getQuestionResponse, getMetadataQueryResponse, getResponse } = useApi();
  const [isResponseLoading, setIsResponseLoading] = useState(false);
  const { messages, addServerMessage, addUserMessage } = useMessages(WELCOME_MESSAGE);
  const [osduContextMapInMemory, setOsduContextMapInMemory] = useState(CONTEXT_OPTIONS);

  const sendMessage = async (message: string) => {
    if (osduContext) {
      let questionDocuments = osduContextMapInMemory.has(osduContext)
        ? osduContextMapInMemory.get(osduContext)?.map((tuple: any) => tuple[1])
        : documentId;
      if (questionDocuments?.includes(documentId)) {
        questionDocuments = documentId;
      }
      setIsResponseLoading(true);
      let response = await getQuestionResponse(message, questionDocuments);
      console.log("questionResponse: ", response)
      console.log("questionResponse.status : ", response.status)
      const transactionId = response.transaction_id

      while (response.status == "in-process" && response != null) {
        await delay(5000)
        response = await getResponse(transactionId);
        console.log("getResponse.status: ", response.status)
      }
      setIsResponseLoading(false);
      if (response.status == "processed") {
        addServerMessage(response.response);
      } else {
        addServerMessage("Something went wrong, please try again");
      }
    } else {
      setIsResponseLoading(true);
      let response = await getMetadataQueryResponse(message);
      const transactionId = response.transaction_id
      while (response.status == "in-process" && response != null) {
        await delay(5000)
        response = await getResponse(transactionId);
        console.log("getResponse.status: ", response.status)
      }
      setIsResponseLoading(false);
      if (response.status == "processed") {
        addServerMessage(response.response);
      } else {
        addServerMessage("Something went wrong, please try again");
      }
    }
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  return (
    <AIAssistantContext.Provider
      value={{
        documentId,
        setDocumentId,
        osduContext,
        setOsduContext,
        osduContextMapInMemory,
        setOsduContextMapInMemory,
        contextDocumentIds,
        setContextDocumentIds,
        isResponseLoading,
      }}
    >
      <AIChatbotContainer>
        <DocumentSettingsPanel />
        <ChatMessagesArea messages={messages} messagesEndRef={messagesEndRef} />
        <QuestionsPanel
          sendMessage={sendMessage}
          addUserNewMessage={addUserMessage}
          textareaRef={textareaRef}
        />
      </AIChatbotContainer>
    </AIAssistantContext.Provider>
  );
}
