import React, { ReactElement, useContext, useState } from 'react';
import {
  TextBoxContainer,
  InputStyled,
  DropdownButtonStyled,
  DropdownOptionsContainer,
  OptionStyled,
  optionHovered,
  optionsOpenStyle,
  OptionListContainer,
} from './DocumentsDropdown.style';
import { FILE_OPTIONS } from '../../../AIAssistant/consts';
import AIAssistantContext from 'pages/AIAssistant/context/AIAssistant';

interface Props {
  selectedOption: string;
  setSelectedOption: React.Dispatch<React.SetStateAction<string>>;
}

export default function DocumentsDropdown({
  selectedOption,
  setSelectedOption,
}: Props): ReactElement {
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [hoveredOption, setHoveredOption] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const { documentId, setDocumentId, contextDocumentIds } = useContext(AIAssistantContext);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDocumentId!(event.target.value);
    setSelectedOption('');
  };

  const handleDropdownClick = () => {
    setOptionsOpen(!optionsOpen);
    setSubmitted(false);
    // setTextBoxGreen(false);
  };

  const handleOptionSelect = (optionValue: string) => {
    setSelectedOption(optionValue);
    setOptionsOpen(false);
    setDocumentId!(optionValue);
    // setTextBoxGreen(false);
  };

  const handleOptionHover = (optionValue: string) => {
    setHoveredOption(optionValue);
  };

  const handleInputFocus = () => {
    // setTextBoxGreen(false); // Reset the green border when the input is focused
    setSelectedOption('');
  };

  return (
    <div className={`custom-dropdown ${submitted ? 'submitted' : ''}`}>
      <TextBoxContainer className="input-with-icon">
        <InputStyled
          type="text"
          id="documentId"
          className={`form-control ${submitted ? 'submitted' : ''}`}
          value={documentId}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          readOnly={false}
          placeholder="Type or select a document"
          onClick={() => {
            setOptionsOpen(false);
            setSubmitted(false);
          }} // Close options and reset submitted on input click
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              e.stopPropagation();
              handleDropdownClick();
            }
          }}
        />
        <DropdownButtonStyled
          props={{ optionsOpen }}
          onClick={(e) => {
            handleDropdownClick();
            setSubmitted(false);
            e.stopPropagation();
          }}
        >
          &#9660;
        </DropdownButtonStyled>
      </TextBoxContainer>
      {optionsOpen && (
        <DropdownOptionsContainer
          props={{ optionsOpen }}
          className={`options ${optionsOpen ? 'open' : ''}`}
        >
          <OptionListContainer className="options-list">
            {contextDocumentIds!.map((fileOption: any) => (
              <OptionStyled
                key={fileOption}
                className={[
                  'option',
                  selectedOption === fileOption ? 'selected' : '',
                  hoveredOption === fileOption ? 'hovered' : '',
                ].join(' ')}
                onClick={() => handleOptionSelect(fileOption)}
                onMouseEnter={() => handleOptionHover(fileOption)}
                onMouseLeave={() => handleOptionHover('')}
                style={{
                  ...(hoveredOption === fileOption ? optionHovered : optionsOpenStyle),
                }}
              >
                {fileOption}
              </OptionStyled>
            ))}
          </OptionListContainer>
        </DropdownOptionsContainer>
      )}
    </div>
  );
}
