import { ReactElement, useContext } from 'react';
import { SetContextButtonStyle } from './SetContextButton.style';
import AIAssistantContext from 'pages/AIAssistant/context/AIAssistant';
import useApi from 'pages/AIAssistant/hooks/useApi';

interface Props {
  selectedOsduContextOption: string;
  inputRef: any;
}

function uniq(a: any) {
  return Array.from(new Set(a));
}

export default function SetContextButton({
  selectedOsduContextOption,
  inputRef,
}: Props): ReactElement {
  const {
    setOsduContext,
    osduContextMapInMemory,
    setOsduContextMapInMemory,
    setContextDocumentIds,
  } = useContext(AIAssistantContext);
  const { getContext } = useApi();
  const handleButtonClick = async () => {
    let contextCandidate = selectedOsduContextOption || inputRef.current.value;
    contextCandidate = contextCandidate == 'No context' ? '' : contextCandidate;
    if (
      contextCandidate != '' &&
      osduContextMapInMemory &&
      !osduContextMapInMemory.has(contextCandidate)
    ) {
      let contextResponse = await getContext(contextCandidate);
      contextResponse = contextResponse.map((x: any) => [x['dataset-id'], x['file-name']]);
      contextResponse = uniq(contextResponse);
      if (!contextResponse) return;

      const newMap = new Map(osduContextMapInMemory);
      newMap.set(contextCandidate, contextResponse);
      setOsduContextMapInMemory!(newMap);
    }
    setOsduContext?.(contextCandidate);
    const files: string[] = uniq(
      osduContextMapInMemory!.get(contextCandidate)?.map((x) => x[1])
    ) as string[];
    setContextDocumentIds!(files);
  };
  return (
    <SetContextButtonStyle className="btn btn-primary" onClick={handleButtonClick}>
      Set context
    </SetContextButtonStyle>
  );
}
