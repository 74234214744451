import { QUESTIONS_BY_DOCUMENT_ID } from 'pages/AIAssistant/consts';
import React, { ReactElement, useContext } from 'react';
import { QuestionDropdownContainer, QuestionSelect } from './QuestionsListDropdown.style';
import AIAssistantContext from 'pages/AIAssistant/context/AIAssistant';

interface Props {
  selectedDocumentIdQuestion: any;
  setSelectedDocumentIdQuestion: any;
}

export default function QuestionsListDropdown({
  selectedDocumentIdQuestion,
  setSelectedDocumentIdQuestion,
}: Props): ReactElement {
  const handleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedDocumentIdQuestion(event.target.value);
  };

  const { documentId } = useContext(AIAssistantContext);

  return (
    <QuestionDropdownContainer className="input-group mb-3">
      <QuestionSelect
        id="questionDropdown"
        className="form-control"
        value={selectedDocumentIdQuestion}
        onChange={handleDropdownChange}
      >
        <option value="">Sample questions...</option>
        {QUESTIONS_BY_DOCUMENT_ID[documentId!]?.map((question: string) => (
          <option key={question} value={question}>
            {question}
          </option>
        ))}
      </QuestionSelect>
    </QuestionDropdownContainer>
  );
}
