import { ReactElement, useContext } from 'react';
import { TextareaStyled } from './QuestionTextarea.style';

interface Props {
  textareaRef: any;
  handleKeyDown: any;
}

export default function QuestionTextarea({ textareaRef, handleKeyDown }: Props): ReactElement {
  return (
    <TextareaStyled ref={textareaRef} onKeyDown={handleKeyDown} placeholder="Type something..." />
  );
}
