import styled from 'layout/theme/styled';

export const TextareaStyled = styled('textarea')({
  width: '800px',
  height: 'calc(80% - 40px)',
  resize: 'none',
  border: '1px solid black',
  padding: '10px',
  borderRadius: '10px',
});
