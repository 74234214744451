import { ReactElement } from 'react';
import { ViewPdfButton } from './OpenPdfButton.style';

import useApi from '../../hooks/useApi';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

interface OpenPdfButtonProps {
  selectedOption: string;
}

export default function OpenPdfButton({ selectedOption }: OpenPdfButtonProps): ReactElement {
  const { getPdfSignedUrl } = useApi();
  const dataPartitionId = useSelector((state: RootState) => state.settings.dataPartitionId);

  const handleDocumentButtonClick = async () => {
    let datasetRegistryIds;
    if (selectedOption === 'lithology_1034039.pdf') {
      datasetRegistryIds = ['osdu:dataset--File.Generic:297fdd75-3c2d-4d38-bd3f-6d7753cd9e12'];
    } else if (selectedOption === 'forge_well_1034058.pdf') {
      datasetRegistryIds = ['osdu:dataset--File.Generic:de7393cc-eb01-4b8e-ba19-43fdac8a1867'];
    } else if (selectedOption === '58-32_eowr_doe.pdf') {
      datasetRegistryIds = ['osdu:dataset--File.Generic:5832EOWRDOEpdf'];
    } else if (selectedOption === 'coringreport.pdf') {
      datasetRegistryIds = ['osdu:dataset--File.Generic:CoringReportpdf'];
    }
    const signedUrlFromResponse = await getPdfSignedUrl(dataPartitionId, datasetRegistryIds);
    if (signedUrlFromResponse) {
      window.open(signedUrlFromResponse, '_blank');
    } else {
      console.error('Signed URL not available');
    }
  };
  return (
    <ViewPdfButton className="btn btn-primary" onClick={handleDocumentButtonClick}>
      View PDF
    </ViewPdfButton>
  );
}
