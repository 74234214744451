import styled from 'layout/theme/styled';

export const optionHovered: React.CSSProperties = {
  backgroundColor: '#adcfe6',
  paddingLeft: '2px',
};

export const optionsOpenStyle: React.CSSProperties = {
  paddingLeft: '2px',
  cursor: 'default',
  transition: 'background-color 0.2s',
};

export const TextBoxContainer = styled('div')({
  display: 'flex',
  position: 'relative',
  width: '139%',
  justifyContent: 'center',
});
export const InputStyled = styled('input')({
  position: 'relative',
  width: '120%',
  padding: '5px 15px',
  border: '2.5px solid black',
  borderRadius: '4px',
  outline: 'none',
  transition: 'border-color 0.2s',
});

export const DropdownButtonStyled = styled('button')<{ props: { optionsOpen?: boolean } }>(
  ({ props: { optionsOpen } }) => ({
    background: optionsOpen ? '#053157' : '#044379',
    border: '1.5px solid black',
    cursor: 'default',
    position: 'absolute',
    right: '0rem',
    top: '77%',
    transform: 'translateY(-80%)',
    height: '94%',
    width: '2rem',
    borderRadius: '3px',
    alignItems: 'center',
    color: 'white',
  })
);
export const DropdownOptionsContainer = styled('div')<{ props: { optionsOpen?: boolean } }>(
  ({ props: { optionsOpen } }) => ({
    cursor: 'default',
    paddingLeft: optionsOpen ? '2px' : 'default',
    transition: optionsOpen ? 'background-color 0.2s' : 'default',
    position: optionsOpen ? 'absolute' : 'static',
    zIndex: 1000,
  })
);
export const OptionStyled = styled('option')({});

export const OptionListContainer = styled('div')({
  border: '1px solid black',
  backgroundColor: 'white',
  width: '275px',
  borderRadius: '4px',
});
