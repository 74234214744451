import styled from 'layout/theme/styled';
import Button from '@mui/material/Button';

export const ButtonBox = styled(Button)({
  position: 'relative',
  width: '190px',
  height: '36px',
  padding: '5px 15px',
  border: '1.5px solid black',
  borderRadius: '4px',
  outline: 'none',
  transition: 'border-color 0.2s',
  backgroundColor: '#044379',
  color: 'white',
  marginLeft: '7em',
  boxShadow: '0 3px 6px rgba(0,0,0,0.2)',
  alignSelf: 'flex-start',
});

export const ViewPdfButton = styled(ButtonBox)({
  marginLeft: '90px',
  cursor: 'pointer',
});
