import styled from 'layout/theme/styled';

export const MainContainer = styled('div')({
  // display: "flex",
  justifyContent: 'center',
});

export const ContextSpan = styled('span')({
  fontWeight: 'bold',
  color: '#044379',
});

export const HeadlineContainer = styled('div')({
  marginBottom: '1em',
  display: 'flex',
  justifyContent: 'center',
});

export const HeadlineLabel = styled('label')({
  color: 'black',
});

export const DocumentSettingsContainer = styled('div')({
  display: 'flex',
  padding: '5px',
  alignItems: 'center',
  border: 'transparent',
  width: '60%',
  marginLeft: '1em',
  justifyContent: 'center',
  minWidth: '600px',
});
