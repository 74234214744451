import React, { ReactElement, useContext, useState } from 'react';

import EnterQuestionButton from '../EnterQuestionButton/EnterQuestionButton';
import QuestionsListDropdown from '../QuestionsListDropdown/QuestionsListDropdown';
import QuestionTextarea from '../QuestionTextarea/QuestionTextarea';
import { QuestionsPanelContainer, QuestionsFormContainer } from './QuestionsPanel.style';
import AIAssistantContext from 'pages/AIAssistant/context/AIAssistant';

interface Props {
  sendMessage: any;
  addUserNewMessage: any;
  textareaRef: any;
}

export default function QuestionsPanel({
  sendMessage,
  addUserNewMessage,
  textareaRef,
}: Props): ReactElement {
  const [selectedDocumentIdQuestion, setSelectedDocumentIdQuestion] = useState('');
  const { isResponseLoading } = useContext(AIAssistantContext);
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const newMessage = textareaRef.current?.value.trim();
    if (newMessage) {
      addUserNewMessage(newMessage);
      textareaRef.current!.value = '';
      sendMessage(newMessage);
    }
  };

  //Keyboard Enter Button
  const handleKeyDown = async (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (isResponseLoading) return;
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      const newMessage = event.currentTarget.value.trim();
      if (newMessage !== '') {
        event.currentTarget.value = '';
        // Add the user's message to the messages state immediately
        addUserNewMessage(newMessage);
        await sendMessage(newMessage);
      }
    }
  };
  return (
    <QuestionsPanelContainer>
      <form onSubmit={handleSubmit}>
        <QuestionsFormContainer>
          <QuestionTextarea textareaRef={textareaRef} handleKeyDown={handleKeyDown} />
          <QuestionsListDropdown
            selectedDocumentIdQuestion={selectedDocumentIdQuestion}
            setSelectedDocumentIdQuestion={setSelectedDocumentIdQuestion}
          />
          <EnterQuestionButton
            textareaRef={textareaRef}
            selectedDocumentIdQuestion={selectedDocumentIdQuestion}
            addUserNewMessage={addUserNewMessage}
            sendMessage={sendMessage}
          />
        </QuestionsFormContainer>
      </form>
    </QuestionsPanelContainer>
  );
}
