import { ReactElement, useContext, useState } from 'react';
import OpenPdfButton from '../OpenPdfButton';
import DocumentsDropdown from '../DocumentsDropdown';
import SetContextDropdown from '../SetContextDropdown';
import {
  MainContainer,
  HeadlineContainer,
  HeadlineLabel,
  DocumentSettingsContainer,
  ContextSpan,
} from './DocumentSettingsPanel.style';
import { FILE_OPTIONS } from 'pages/AIAssistant/consts';
import AIAssistantContext from 'pages/AIAssistant/context/AIAssistant';

export default function DocumentSettingsPanel(): ReactElement {
  const [selectedOption, setSelectedOption] = useState(FILE_OPTIONS[0].value);
  const [selectedOsduContextOption, setSelectedOsduContextOption] = useState('');

  const { osduContext } = useContext(AIAssistantContext);
  return (
    <MainContainer>
      <HeadlineContainer>
        <HeadlineLabel htmlFor="documentId">
          Enter a Document ID and set context to start conversation with your AI Assistant
        </HeadlineLabel>
      </HeadlineContainer>
      <div>
        <DocumentSettingsContainer className="input-group mb-3">
          <SetContextDropdown
            selectedOsduContextOption={selectedOsduContextOption}
            setSelectedOsduContextOption={setSelectedOsduContextOption}
          />
        </DocumentSettingsContainer>

        <DocumentSettingsContainer>
          <DocumentsDropdown
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
          <OpenPdfButton selectedOption={selectedOption} />
        </DocumentSettingsContainer>
        <br></br>
        <HeadlineContainer>
          {osduContext == '' ? (
            <span>
              {' '}
              There is no context picked. <ContextSpan> You are in context-free mode. </ContextSpan>
            </span>
          ) : (
            <span>
              {' '}
              Currently set context: <ContextSpan> {osduContext} </ContextSpan>
            </span>
          )}
        </HeadlineContainer>
      </div>
    </MainContainer>
  );
}
