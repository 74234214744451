import { useEffect, useRef, useState } from 'react';

const useDetectOutsideClick = (initialIsVisible: boolean) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const [isVisible, setIsVisible] = useState(initialIsVisible);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (ref.current !== null && !ref.current.contains(event.target as Node)) {
        setIsVisible(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return [ref, isVisible, setIsVisible] as const;
};

export default useDetectOutsideClick;
