import { IServiceData } from 'types/Platform';

export const services: Array<IServiceData> = [
  {
    name: 'Search',
    uri: '/api/search/v2/health/readiness_check',
    status: 502,
  },
  {
    name: 'Storage',
    uri: '/api/storage/v2/health',
    status: 502,
  },
  {
    name: 'Entitlements',
    uri: '/api/entitlements/v2/_ah/readiness_check',
    status: 502,
  },
  {
    name: 'Dataset',
    uri: '/api/dataset/v1/info',
    status: 502,
  },
  {
    name: 'File',
    uri: '/api/file/v2/readiness_check',
    status: 502,
  },
  {
    name: 'Indexer',
    uri: '/api/indexer/v2/readiness_check',
    status: 502,
  },
  {
    name: 'Unit',
    uri: '/api/unit/_ah/readiness_check',
    status: 502,
  },
  {
    name: 'Partition',
    uri: '/api/partition/v1/info',
    status: 502,
  },
  {
    name: 'Well Delivery',
    uri: '/api/well-delivery/_ah/warmup',
    status: 502,
  },
  {
    name: 'Legal',
    uri: '/api/legal/v1/_ah/readiness_check',
    status: 502,
  },
  {
    name: 'Notification',
    uri: '/api/notification/v1/_ah/warmup',
    status: 502,
  },
  {
    name: 'Register',
    uri: '/api/register/v1/ah/liveness_check',
    status: 502,
  },
  {
    name: 'Schema',
    uri: '/api/schema-service/v1/info',
    status: 502,
  },
  {
    name: 'Policy',
    uri: '/api/policy/v1/version',
    status: 502,
  },
];
