import { useState, useCallback } from 'react';
import { MessageType } from '../consts';

const useMessages = (initialMessage: MessageType) => {
  const [messages, setMessages] = useState<MessageType[]>([initialMessage]);

  const addServerMessage = useCallback((content: string) => {
    setMessages((prevMessages) => [...prevMessages, { type: 'server', content }]);
  }, []);

  const addUserMessage = useCallback((content: string) => {
    setMessages((prevMessages) => [...prevMessages, { type: 'user', content }]);
  }, []);

  const addOtherMessage = useCallback((content: string) => {
    setMessages((prevMessages) => [...prevMessages, { type: 'other', content }]);
  }, []);

  const clearMessages = useCallback(() => {
    setMessages([]);
  }, []);

  return {
    messages,
    addServerMessage,
    addUserMessage,
    addOtherMessage,
    clearMessages,
  };
};

export default useMessages;
