import SettingsIcon from '@mui/icons-material/Settings';
import { IPageInformation } from '../Pages.d';
import AITab from './components/AITab/AITab';

const TITLE = 'AI Assistant';
const URL = '/'.concat(TITLE.replace(/\s+/g, ''));

const AIAssistant: IPageInformation[] = [
  {
    title: TITLE,
    path: URL,
    addToSideMenu: true,
    icon: <SettingsIcon />,
    component: () => <AITab />,
  },
];

export default AIAssistant;
