import React, { ReactElement, useContext, useRef, useState } from 'react';
import {
  TextBoxContainer,
  InputStyled,
  DropdownButtonStyled,
  DropdownOptionsContainer,
  OptionStyled,
  optionHovered,
  optionsOpenStyle,
  OptionListContainer,
} from './SetContextDropdown.style';
import AIAssistantContext from 'pages/AIAssistant/context/AIAssistant';
import useDetectOutsideClick from 'pages/AIAssistant/hooks/useDetectOutsideClick';
import SetContextButton from '../SetContextButton/SetContextButton';

interface Props {
  selectedOsduContextOption: string;
  setSelectedOsduContextOption: React.Dispatch<React.SetStateAction<string>>;
}

export default function SetContextDropdown({
  selectedOsduContextOption,
  setSelectedOsduContextOption,
}: Props): ReactElement {
  const [dropdownRef, isDropdownVisible, setIsDropdownVisible] = useDetectOutsideClick(false);
  const [hoveredOption, setHoveredOption] = useState('');
  const { osduContextMapInMemory } = useContext(AIAssistantContext);
  const inputRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const handleOptionSelect = (optionValue: string) => {
    setSelectedOsduContextOption(optionValue);
    setIsDropdownVisible(false);
  };

  const handleOptionHover = (optionValue: string) => {
    setHoveredOption(optionValue);
  };

  const handleInputFocus = (event: any) => {};

  function changeText(event: any) {
    setSelectedOsduContextOption(event.target.value);
  }
  return (
    <>
      <div className={`custom-dropdown`}>
        <TextBoxContainer className="input-with-icon">
          <InputStyled
            ref={inputRef}
            type="text"
            autoComplete="off"
            id="documentId"
            className={`form-control`}
            value={selectedOsduContextOption}
            onChange={changeText}
            onFocus={handleInputFocus}
            placeholder="Type or select an osdu object"
          />
          <DropdownButtonStyled
            props={{ isDropdownVisible }}
            onClick={(e) => {
              toggleDropdown();
              e.stopPropagation();
            }}
          >
            &#9660;
          </DropdownButtonStyled>
        </TextBoxContainer>
        {isDropdownVisible && (
          <DropdownOptionsContainer
            props={{ isDropdownVisible }}
            className={`options ${isDropdownVisible ? 'open' : ''}`}
            ref={dropdownRef}
          >
            <OptionListContainer className="options-list">
              {Array.from(osduContextMapInMemory!.keys()).map((contextOption: any) => (
                <OptionStyled
                  key={contextOption}
                  className={[
                    'option',
                    selectedOsduContextOption === contextOption ? 'selected' : '',
                    hoveredOption === contextOption ? 'hovered' : '',
                  ].join(' ')}
                  onClick={() => handleOptionSelect(contextOption)}
                  onMouseEnter={() => handleOptionHover(contextOption)}
                  onMouseLeave={() => handleOptionHover('')}
                  style={{
                    ...(hoveredOption === contextOption ? optionHovered : optionsOpenStyle),
                  }}
                >
                  {contextOption}
                </OptionStyled>
              ))}
            </OptionListContainer>
          </DropdownOptionsContainer>
        )}
      </div>
      <SetContextButton selectedOsduContextOption={selectedOsduContextOption} inputRef={inputRef} />
    </>
  );
}
