import { Auth } from '@aws-amplify/auth';

const get_auth_header = async () => {
  const session = await Auth.currentSession();
  const idTokenExpire = session.getIdToken().getExpiration();
  const currentTimeSeconds = Math.round(+new Date() / 1000);
  const willExpireInSeconds = idTokenExpire - currentTimeSeconds;
  if (willExpireInSeconds < 100) {
    console.log('refreshing user session');
    const user = await Auth.currentAuthenticatedUser();
    user.refreshSession(session.getRefreshToken(), (err, data) => {
      if (err) {
        Auth.signOut();
        return {};
      } else {
        console.log('refreshed user session');
        return { Authorization: `Bearer ${data.getAccessToken().getJwtToken()}` };
      }
    });
  }
  return { Authorization: `Bearer ${session.getAccessToken().getJwtToken()}` };
}

export default {
  Auth: {
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOLS_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOLS_WEB_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: 'api',
        endpoint: process.env.REACT_APP_BASE_API_URL,
        region: process.env.REACT_APP_REGION,
        custom_header: get_auth_header,
      },
      {
        name: 'cognito-admin-api',
        endpoint: process.env.REACT_APP_USR_MGMT_URL,
        region: process.env.REACT_APP_REGION,
        custom_header: get_auth_header,
      },
      {
        name: 'platform-state-api',
        endpoint: process.env.REACT_APP_PLATFORM_STATE_API,
        region: process.env.REACT_APP_REGION,
        custom_header: get_auth_header,
      },
      {
        name: 'ai-assistant-api',
        endpoint: process.env.REACT_APP_AI_ASSISTANT_API_URL,
        region: process.env.REACT_APP_REGION,
        custom_header: get_auth_header,
      },
    ],
  },
};
