import styled from 'layout/theme/styled';

export const EnterQuestionButtonStyled = styled('button')({
  marginTop: '1em',
  width: '300px',
  backgroundColor: '#044379',
  color: 'white',
  padding: '8px',
  border: 'none',
  cursor: 'pointer',
  boxShadow: '0 3px 6px rgba(0,0,0,0.2)',
  borderRadius: '4px',

  '&:disabled': {
    // color: '#666',
    backgroundColor: '#666',
  },
});
