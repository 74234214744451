import styled from 'layout/theme/styled';

export const QuestionsPanelContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  right: '1000px',
  marginTop: '1em',
});

export const QuestionsFormContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  overflow: 'auto',
  height: '200px',
});
