import { ReactElement, useContext } from 'react';
import { MessageType } from 'pages/AIAssistant/consts';

import {
  ChatMessagesContainer,
  ResponseBoxContainer,
  MessageContainer,
  Loading,
} from './ChatMessagesArea.style';
import AIAssistantContext from 'pages/AIAssistant/context/AIAssistant';

interface ChatMessagesAreaProps {
  messages: MessageType[];
  messagesEndRef: any;
}

export default function ChatMessagesArea({
  messages,
  messagesEndRef,
}: ChatMessagesAreaProps): ReactElement {
  const { isResponseLoading } = useContext(AIAssistantContext);
  return (
    <ChatMessagesContainer>
      <ResponseBoxContainer>
        {messages.map((message: MessageType, index: number) => (
          <MessageContainer props={{ message }} key={index}>
            {message.content}
          </MessageContainer>
        ))}
        {isResponseLoading && (
          <MessageContainer props={{ message: { type: 'server', content: 'Loading' } }}>
            <Loading />
          </MessageContainer>
        )}
        <div ref={messagesEndRef} />
      </ResponseBoxContainer>
    </ChatMessagesContainer>
  );
}
